<template>
    <div class="error-text">
        <feather :type="icon" v-if="icon != ''"></feather>
        <span>{{ message == '' ? getError(type) : message }}</span>
    </div>
</template>
<script>
export default {
    name: "error-component",
    props: {
        icon: { type: String, default: "alert-circle"},
        text: { type: String, default: ""},
        type: { type: String, default: "required"}
    },
    data () {
        return {
            message: this.text
        }
    },
    methods: {
        getError(val){
            let response = this.$t('errors.required')
            switch (val) {
                case 'phone':
                    response = this.$t('errors.phoneNumber')
                    break;
            }
            return response
        }
    }
}
</script>
