<template>
    <div class="login">
        <div class="form-login">                    
            <div class="title">Iniciar sesión</div>
            <div :class="['input-row', {'has-error': $v.user.email.$error}]">
                <vs-input label="Email" v-model="$v.user.email.$model"/>
                <error v-if="$v.user.email.$error"></error>
            </div>
            <div :class="['input-row', {'has-error': $v.user.password.$error}]">
                <vs-input :type="typeInput" label="Contraseña" placeholder="" v-model="$v.user.password.$model"/>
                <div class="wrap-eye-pass">
                    <feather :type="eye" class="displayPasss" @click="displayPass"></feather>
                </div>
                <error v-if="$v.user.password.$error"></error>
            </div>
            <div class="d-flex">
                <checkbox id="1" title="Recordar"></checkbox>
                <a href="javascript:void(0)" class="forgot-password" @click="openRecoveryPass">¿Olvidaste tu contraseña?</a>
            </div>
            <div class="wrap-button">
                <vs-button color="primary" type="filled" id="btnLogin" 
                    class="vs-con-loading__container" :disabled="disabled" @click="login()">Ingresar</vs-button>
                <div class="error-login">
                    <vs-alert :active="errorLogin" color="danger" icon="new_releases" >
                        <span>{{$t('errors.login')}}</span>
                    </vs-alert>
                </div>
            </div>

        </div>
        <modal :show="showModal" title="Información" color="primary" icon="mail">
            <div slot="modal-body">
                <p>Por favor ingresar el Correo Electrónico registrado, para reestablecer tu contraseña</p>
                <div class="input-row recovery-password">
                    <vs-input  v-model="recoveryMail"/>
                </div>
            </div>
            <div class="modal-footer" slot="modal-footer">
                <a href="javascript:void(0)" class="cancel" @click="closeModal">Cancelar</a>
                <vs-button color="primary"  href="javascript:void(0)" id="btnRecovery" 
                            class="vs-con-loading__container" :disabled="$v.recoveryMail.$invalid || disabledRecovery" @click="resetPassword">Enviar</vs-button>
            </div>  
        </modal>  
    </div>
</template>
<script>
import error from '@/components/_error.vue'
import { createNamespacedHelpers } from 'vuex'
import UserService from "@/services/UserService"
import modal from '@/components/Modals/Basic.vue'
import { required, email } from 'vuelidate/lib/validators'
import checkbox from '@/components/Inputs/_checkbox.vue'
const  { mapGetters: mapUserGetter, mapActions: mapUserActions } = createNamespacedHelpers('user')
export default {
    name: "login-page",
    components: {
        modal,
        error,
        checkbox
    },
    data () {
        return {
            eye: 'eye-off',
            user: {
                password: "",
                email: "",
            },
            typeInput: "password",
            loading: false,
            showModal: false,
            disabled: false,
            modalTitle: "",
            modalMessage: "",
            recoveryMail: '',
            errorLogin: false,
            disabledRecovery: false,
        }
    },
    validations: {
        user: {
            email: { required },
            password : { required }
        },
        recoveryMail: { required, email }
    },
    beforeMount() {
        console.log('this.GET_CURRENT_USER', this.GET_CURRENT_USER)
        if (this.GET_CURRENT_USER != null) {
            this.$router.push('/');
        }
    },
    computed: { 
        ...mapUserGetter(["GET_CURRENT_USER"]),
    },
    methods: {
        ...mapUserActions([
            'LOGIN2',
        ]),
        displayPass(){
            this.eye = this.eye == 'eye-off' ? 'eye' : 'eye-off'
            this.typeInput = this.typeInput == 'password' ? 'text' : 'password'
        },
        closeModal () {
            this.recoveryMail = ''
            this.showModal = false
            this.disabledRecovery = false
            this.$vs.loading.close('#btnRecovery .con-vs-loading')
        },
        openRecoveryPass() {
            this.modalTitle   = this.$t('auth.recoveryTitle')
            this.modalMessage = this.$t('auth.recoveryInfo')
            this.showModal = true
        },
        async login(){
            this.$v.user.$touch()
            if(!this.$v.user.$invalid){
                this.disabled = true
                this.$vs.loading({ background: 'primary', color: 'white', container: '#btnLogin', scale: 0.45})
                localStorage.setItem('USER', JSON.stringify(this.user))
                // let response = await this.LOGIN2(this.user)
                // console.log(response);
                // if(response) {
                    this.$router.push('/')
                // } else {
                //     this.errorLogin = true
                //     setTimeout( ()=> {
                //         this.errorLogin = false
                //     }, 6000);
                // }
                setTimeout( ()=> {
                    this.disabled = false
                    this.$vs.loading.close('#btnLogin .con-vs-loading')
                }, 1000);
            }
        },
        async resetPassword () {
            this.$v.recoveryMail.$touch();
            if(!this.$v.recoveryMail.$invalid){
                this.disabledRecovery= true
                this.$vs.loading({ background: 'primary', color: 'white', container: '#btnRecovery', scale: 0.45})
                let response = await UserService.recovery({ email: this.recoveryMail})
                if(response){
                    this.recoveryMail = ''
                    this.showModal = false
                    this.$vs.notify({  time:4000, title: this.$t('keywords.information'),text: this.$t('messages.recovery.success'),color:'success',icon:'mail'})
                }
            }
            setTimeout( ()=> {
                this.disabledRecovery = false
                this.$vs.loading.close('#btnRecovery .con-vs-loading')
            }, 1000);
        }
    }
}
</script>