<template>
    <div class="custom-check">
        <input :class="`chkb-input ${color}`" :id="unique" :disabled="beDisabled" :value="id" type="checkbox" @input="updateVal($event)" :checked="beActive"/>
        <label class="chkb-label" :for="unique"></label>
        <label class="custom-check-label" :for="unique" v-if="title != ''">{{ title }}</label>
        <label class="custom-check-label" :for="unique" v-if="image != ''">
            <img :src="image" alt="" />
        </label>
    </div>
</template>
<script>
import { uuid } from 'vue-uuid'
export default {
    name: "input-checkbox",
    props: {
        id: { default: '' },
        title:   { type: String,  default: '' },
        checked: { type: Boolean, default: false },
        color:   { type: String,  default: 'primary' },
        image:   { type: String,  default: ""},
        disabled:{ type: Boolean, default: false}
    },
    data () {
        return {
            beActive: this.checked,
            beDisabled: this.disabled,
            unique: uuid.v4(),
        }
    },
    watch: {
        checked (val) {
            console.log('Change', val)
            this.beActive = val
        },
        disabled (val) {
            console.log('Change', val)
            this.beDisabled = val
        }
    },
    methods: {
        updateVal (event) {
            this.$emit('input', event.target.checked, event.target.value, )
        }
    }
}
</script>